import React from 'react'
import FMnunber, { getDias,getHoras } from '../helper/helper'
import NumberFormat from 'react-number-format';
import {useTranslation} from "react-i18next"
export default function PanelTable({rows,totalRewards,mystake }) {
    const[t,i18n] = useTranslation("global");
    let options = { year: 'numeric', month: 'numeric', day: 'numeric' };
  return (
        <div className="panel__table">
            <table>
                 <thead className="table-header">
                 <tr>

                    <th className="col-4">{t("Staked.Account")}</th>
                    <th className="col-2">{t("Staked.Amount")}</th>
                    <th className="col-2">{t("Staked.Start")}</th>
                    <th className="col-2">{t("Staked.End")}</th>
                    <th className="col-2">{t("Staked.Time")}</th>
                </tr>  
                </thead>
                <tbody>
                {
           rows.map((row, key) => (
             row[0] > 0 ?
                    (<tr key={key}>
                        <td>{key}</td>
                        <td><NumberFormat value={FMnunber(row[0])} displayType={'text'} thousandSeparator={true}/></td>
                        <td>{new Date(row[2] * 1000).toLocaleString('de-DE', options)}</td>
                        <td>{new Date(row[3] * 1000).toLocaleString('de-DE', options)}</td>
                        <td>{getDias(row[3]) == 0 ? getHoras(row[3]):getDias(row[3])} </td>
                    </tr>): (null)
                    ))} 

                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    {totalRewards > 0 ?(<tr >
                        <td className='bg-warning'><strong>TOTAL</strong></td>
                        <td className='bg-warning '><strong><NumberFormat value={FMnunber(totalRewards)} displayType={'text'} thousandSeparator={true} /></strong></td>
                        <td className='bg-warning'></td>
                        <td className='bg-warning'></td>
                        <td className='bg-warning'></td>
                    </tr>):(null)}
                </tbody>
            </table>
        </div>
  )
}
