import React from 'react'
import {useTranslation} from "react-i18next"
export default function Requisitos() {
    const[t,i18n] = useTranslation("global");
  return (
     <section className="requisitos ">
            <div className="container ">

                <div className="participacion row">

                    <h2 className="text-center subtitle">{t("requirements.Only 2 requirements to participate")}</h2>

                    <div className="participacion-content col-md-6 text-start">
                        <span className="btn-evulus-white ">{t("requirements.GET EVU TOKENS")}</span>
                        <p className='text-start'>{t("requirements.datails get token")}</p>
                        <div className="d-flex participacion-content__items-1 flex-wrap">
                            <a href="https://www.probit.com/app/exchange/EVU-USDT" target="_blank"><img src="assets/icons/probit.svg" alt="probit"/></a>
                            <a href="https://pancakeswap.finance/swap?inputCurrency=0x18B5F22266343cCD180C6285a66cC9A23Dc262E9&outputCurrency=0x55d398326f99059fF775485246999027B3197955" target="_blank"><img src="assets/icons/pancakeswap.svg" alt="pancakeSwap"/></a>
                            <a href="https://www.bitmart.com/trade/en?symbol=EVU_USDT&layout=basic" target="_blank"><img src="assets/icons/bitmart.svg" alt="BitMart"/></a>
                        </div>
                    </div>
                    <div className="participacion-content col-md-6 text-start">
                        <span className="btn-evulus-white ">{t("requirements.WALLETS PERMITTED")}</span>
                        <p className='text-start'>{t("requirements.datails wallet allow")}</p>
                        <div className="d-flex participacion-content__items-1 flex-wrap pt-4">
                            <a href="https://metamask.io" target="_blank"><img src="assets/icons/metatext.svg" alt="metamask"/></a>
                            <a href="https://trustwallet.com" target="_blank"><img src="assets/icons/trusttext.svg" alt="trustwallet"/></a>

                            <a href="https://www.tokenpocket.pro/" target="_blank"><img  src="assets/icons/tokentext.svg" alt="poke"/></a>
                        </div>
                    </div>
                </div>
            </div>
        </section>

  )
}
