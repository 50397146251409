import React from 'react'
import {useTranslation} from "react-i18next"
export default function Pasos() {
    const[t,i18n] = useTranslation("global");
  return (
     <section className="pasos">
            <div className="container">
                <div className="row ">
                    <h2 className="text-center subtitle">{t("pasos.Your Staking in 3 steps")}</h2>

                    <div class="pasos-buttons flex-wrap gap-4 d-flex justify-content-lg-between justify-content-center ">
                        <a  target="_blank" href="https://pancakeswap.finance/swap?inputCurrency=0x18B5F22266343cCD180C6285a66cC9A23Dc262E9&outputCurrency=0x55d398326f99059fF775485246999027B3197955" className="btn-evulus-icon "><img src="assets/icons/icon evu.svg" alt="coin evu"/>{t("pasos.Buy Evu Tokens")}</a>
                        <a target="_blank" href="https://metamask.io/" className="btn-evulus-icon "><img src="assets/icons/wallet.svg" alt="cartera"/>{t("pasos.Your Decentralized Wallet")}</a>
                        <a target="_blank" href="https://staking.evulus.com/staking" className="btn-evulus-icon "><img src="assets/icons/conectar.svg" alt="conectar"/>{t("pasos.Connect Wallet")}</a>
                    </div>
                </div>
            </div>

        </section>
  )
}
