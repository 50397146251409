import React from 'react'
import {useTranslation} from "react-i18next"

export default function Reglas() {
    const[t,i18n] = useTranslation("global");
  
  return (

        <section className="reglas text-start">

            <div className="container ">
      
                <div className="row ">
                    <h2 className="text-center subtitle">{t("rules.Rules to participate in Staking")}</h2>
                    <ul className="reglas__list">
                        <li>{t("rules.rule3")}</li>
                         <li>{t("rules.rule1")}</li>
                        <li>{t("rules.rule2")}</li>
                        <li>{t("rules.rule4")}</li>
                        <li>{t("rules.rule5")}</li>
                    </ul>
                    <div className='text-center'>
                        <a href="https://pancakeswap.finance/swap?inputCurrency=0x18B5F22266343cCD180C6285a66cC9A23Dc262E9&outputCurrency=0x55d398326f99059fF775485246999027B3197955" className="btn-evulus-medium ">{t("header.Buy Evu")}</a>

                    </div>
                </div>
            </div>

        </section>

  )
}
