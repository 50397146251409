import React from 'react'
import {useTranslation} from "react-i18next"
export default function PanelMes({apy,days}) {
    const[t] = useTranslation("global");
    const Meses = [3.5,4,4.8,5.6,6.5,8,9.4,10.4,11,11.5,12.4,12.9];
    let total = 0;
    let d =  days / 30
    for(let i=0; i<d; i++){
        total = total + (apy/100)*Meses[i];
    }

  return (
    <div className="panel__mes">
    <div className="component-mensual">

        <div className="meses">
            <div className="mes-title">{t("Staked.% Monthly")}</div>

            <div className="mes__items-container d-flex">

                <div className="item-mes">
                    <div className="item-mensual__number">1</div>
                    <div className={days > 13 ? "item-mensual__value active":"item-mensual__value"}>{((apy/100)*Meses[0]).toFixed(1)}</div>
                </div>
                <div className="item-mes">
                    <div className="item-mensual__number">2</div>
                    <div className={days > 30 ? "item-mensual__value active":"item-mensual__value"}>{((apy/100)*Meses[1]).toFixed(1)}</div>
                </div>
                <div className="item-mes">
                    <div className="item-mensual__number">3</div>
                    <div className={days > 60 ? "item-mensual__value active":"item-mensual__value"}>{((apy/100)*Meses[2]).toFixed(1)}</div>
                </div>
                <div className="item-mes">
                    <div className="item-mensual__number">4</div>
                    <div className={days > 90 ? "item-mensual__value active":"item-mensual__value"}>{((apy/100)*Meses[3]).toFixed(1)}</div>
                </div>
                <div className="item-mes">
                    <div className="item-mensual__number">5</div>
                    <div className={days > 120 ? "item-mensual__value active":"item-mensual__value"}>{((apy/100)*Meses[4]).toFixed(1)}</div>
                </div>
                <div className="item-mes">
                    <div className="item-mensual__number">6</div>
                    <div className={days > 150 ? "item-mensual__value active":"item-mensual__value"}>{((apy/100)*Meses[5]).toFixed(1)}</div>
                </div>
                <div className="item-mes">
                    <div className="item-mensual__number">7</div>
                    <div className={days > 180 ? "item-mensual__value active":"item-mensual__value"}>{((apy/100)*Meses[6]).toFixed(1)}</div>
                </div>
                <div className="item-mes">
                    <div className="item-mensual__number">8</div>
                    <div className={days > 210 ? "item-mensual__value active":"item-mensual__value"}>{((apy/100)*Meses[7]).toFixed(1)}</div>
                </div>
                <div className="item-mes">
                    <div className="item-mensual__number">9</div>
                    <div className={days > 240 ? "item-mensual__value active":"item-mensual__value"}>{((apy/100)*Meses[8]).toFixed(1)}</div>
                </div>
                <div className="item-mes">
                    <div className="item-mensual__number">10</div>
                    <div className={days > 270 ? "item-mensual__value active":"item-mensual__value"}>{((apy/100)*Meses[9]).toFixed(1)}</div>
                </div>
                <div className="item-mes">
                    <div className="item-mensual__number">11</div>
                    <div className={days > 300 ? "item-mensual__value active":"item-mensual__value"}>{((apy/100)*Meses[10]).toFixed(1)}</div>
                </div>
                <div className="item-mes">
                    <div className="item-mensual__number">12</div>
                    <div className={days > 330 ? "item-mensual__value active":"item-mensual__value"}>{((apy/100)*Meses[11]).toFixed(1)}</div>
                </div>
            </div>

        </div>


        <div className="mensual-total ">
            <div className="mensual-total__title">% Total</div>

            <button type="button" className='btn-evulus-small pb-0'>{(total).toFixed(1)}</button>


        </div>
    </div>


</div>
  )
}
