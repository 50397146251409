import React from 'react';
import ReactDOM from 'react-dom';
import { Web3ReactProvider } from '@web3-react/core'
import Web3 from 'web3'
import './index.css';
import App from './App';
import {I18nextProvider} from "react-i18next"
import i18next from "i18next"

import global_es from "../src/components/lang/es/global.json"
import global_en from "../src/components/lang/en/global.json"



const getLibrary = (provider) => {
  return  new Web3(provider)
}
i18next.init({
  interpolation: {escapeValue: false},
  lng: "en",
  resources:{
    es:{
      global:global_es,
    },
    en:{
      global:global_en
    }
  },
})

ReactDOM.render(

  <Web3ReactProvider getLibrary={getLibrary}>
    <I18nextProvider i18n={i18next}>
     <App />

    </I18nextProvider>
    </Web3ReactProvider>,
  document.getElementById('root')
);

