
const CoinGecko = require('coingecko-api');

  const Probit = async()=>{
      try {
          const CoinGeckoClient = new CoinGecko();
          let data = await CoinGeckoClient.exchanges.fetchTickers("probit", {
          coin_ids: ["evulus"]
      });
      if(data){
        return data.data.tickers[0].last;
      }else{
        return 0;
      }
      } catch (error) {
        return 0;
      }

  


}

export default Probit;