import React from 'react'
import {useTranslation} from "react-i18next"
export default function Contactanos() {
    const[t,i18n] = useTranslation("global");
  return (
     <section className="contact ">
            <div className="container contact__content ">
                <h3>{t("contactanos.Let's keep in touch")}</h3>
                <h2>{t("contactanos.Join our community")}</h2>
                <ul class="social-icons social-icons--white d-flex align-items-center pt-5 ">
                    <li>
                        <a href="https://www.facebook.com/evulus2.0" target="_blank "><img src="assets/icons/icons-green/facebook.svg" alt="facebook "/>
                        <i/>
                        </a>
                    </li>
                    <li>
                        <a href="https://twitter.com/Evulusoficial" target="_blank "><img src="assets/icons/icons-green/twitter.svg" alt="twitter"/><i/>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.youtube.com/channel/UCNNM4FlohL8RPEg8X2AFavA" target="_blank "><img src="assets/icons/icons-green/youtube.svg" alt="youtube"/><i/>
                        </a>
                    </li>
                    <li>
                        <a href="https://t.me/Evulus_Esp" target="_blank "><img src="assets/icons/icons-green/telegram.svg" alt=" "/><i/>
                        </a>
                    </li>
                </ul>
            </div>
            <a href="# " className="go-top ">
                <i className="fas fa-angle-up "></i>
            </a>
        </section>

  )
}
