import * as React from 'react';
import ModalCalc from './stake/ModalCalc';
import {useTranslation} from "react-i18next"

const Header = ({conectar,active,apy}) => {
  const[t,i18n] = useTranslation("global");

  const [idioma, setIdioma] = React.useState(localStorage.getItem('idioma'));




const changeIdioma = (es) => {
  setIdioma(es)
  localStorage.setItem('idioma',es)

}
React.useEffect(()=> {
  i18n.changeLanguage(idioma)
}, [idioma])
  return (
    <header class="header">
        <nav class="navbar navbar-evulus-black navbar-home navbar-expand-lg  fixed-top navbar-light ">
        <div class="container  d-flex ">
                <a className="navbar-brand logo" href="https://evulus.com">
                    <picture>
                        <source media="(min-width: 992px)" srcSet="https://evulus.com/assets/evulus/assets/icons/logos/logo.svg"/>
                        <source media="(min-width: 80px)" srcSet="assets/icons/logo-claro.svg"/>
                        <img src="https://evulus.com/assets/evulus/assets/icons/logos/logo.svg" alt="logo"/>
                    </picture>
                </a>

                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <i className="fas fa-bars"></i>
                </button>


                <div class=" collapse navbar-collapse" id="navbarSupportedContent">
                <div className="d-none d-lg-flex menu-icon-compact me-xl-5">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0 gap-2 justify-content-center">
                        <li className="nav-item nav-item-home"> 
                        <a  href="https://evulus.com" className="btn-nav"> {t("header.Home")}</a> 
                        </li>
                        <li class="nav-item nav-item-home">
                            <a className="btn-nav" rel="noreferrer" href="https://evulus.com/evulus-token"> Evulus Token</a>
                        </li>
                        <li className="nav-item nav-item-home">
                            <a href="/" className={window.location.pathname === "/staking"  || window.location.pathname === "/" ?("btn-nav active"):("btn-nav")}>Staking</a>
                        </li>
                        <li class="nav-item nav-item-home">
                            <a className="btn-nav"  href="https://academy.evulus.com"> Academy</a>
                        </li>
                        
                        {window.location.pathname == "/" ? (null):(<><li className="nav-item nav-item-home">
                        <button type="button" class="btn-nav" aria-current="page" data-bs-toggle="modal" data-bs-target="#exampleModal">{t("header.Calculator")}</button>
                        </li>

                     
                        <li class="nav-item nav-item-home">
                            <a className="btn-nav" target="_blank" rel="noreferrer" href="https://pancakeswap.finance/swap?inputCurrency=0x18B5F22266343cCD180C6285a66cC9A23Dc262E9&outputCurrency=0x55d398326f99059fF775485246999027B3197955"> {t("header.Buy Evu")}</a>
                        </li></>)}
                        

                    </ul>

                 
                
                    {window.location.pathname == "/" ? (<>   <a href="/staking" class="btn-nav-3">{t("header.Go Staking")}</a>
                    <a href="https://evulus.com/login" class="btn-nav-2">{t("header.Sign In")}</a>
                    <a href="https://evulus.com/register" class="btn-nav-2 active">{t("header.Sign Up")}</a>
                 </>):(<><button className="btn-nav-3" onClick={conectar}>{active ? (t("header.Disconnect Wallet")) : (t("header.Connect Wallet"))}</button></>)}
                 <div class="btn-group btn-lang">
                        <button type="button" class="lang" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">
                              {idioma == 'es' ? ('ESP'):('ENG')}
                            </button>
                        <ul class="dropdown-menu dropdown-menu-end">
                            <li><button class="dropdown-item" type="button" onClick={()=>changeIdioma('en')}>En</button></li>
                            <li><button class="dropdown-item" type="button" onClick={()=>changeIdioma('es')}>Es</button></li>
                
                        </ul>
                    </div>

                    <div>
                        
                    </div>
                </div>


            </div>
        </nav>
        <ModalCalc apy={apy}/>
    </header>
  
  );
};
export default Header;
