import React from 'react'
import {useTranslation} from "react-i18next"
export default function Footer() {
    const[t,i18n] = useTranslation("global");
  return (
    <footer className="footer bg-black">
        <div className="container">
            <div className="row footer__content">
                <div className="col-md-6 text-start">
                    <ul>
                        <li><img width="200px " src="https://evulus.com/assets/img/logo-limpio-claro.svg" alt=" "/></li>
                        <li>
                            <a href="https://evulus.com/register" className="btn btn-evulus-small">{t("fooder.Start Now")}</a>
                        </li>
                       
                        <li>
                            <p> {t("fooder.detal start now")}</p>
                        </li>

                        <ul className=" social-icons social-icons--white d-flex align-items-center ">
                            <li>
                                <a href="https://www.facebook.com/evulus2.0 " target="_blank "><img src="assets/icons/icons-green/facebook.svg" alt="facebook"/><i></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://twitter.com/Evulusoficial " target="_blank "><img src="assets/icons/icons-green/twitter.svg" alt="twitter"/><i></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.youtube.com/channel/UCNNM4FlohL8RPEg8X2AFavA " target="_blank "><img src="assets/icons/icons-green/youtube.svg" alt="youtube"/><i></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://t.me/Evulus_Esp " target="_blank "><img src="assets/icons/icons-green/telegram.svg" alt="telegram"/><i></i>
                                </a>
                            </li>
                        </ul>
                        <div className=" ">
                            <p className="m-0 "> {t("fooder.derechos")}</p>
                        </div>
                        
                        <ul>
                            <li><a href={t("fooder.linkDisclaimer")}> {t("fooder.Disclaimer")}</a></li>
                
                            <li><a href="https://blog.evulus.com/terminos-de-uso-de-evulus/"> {t("fooder.Terms of use")}</a></li>
                            <li><a href="https://blog.evulus.com/politicas-de-cookies-y-otras-tecnologias-de-almacenamiento/"> {t("fooder.Cookie Policies")}</a></li>
                        </ul>
                    </ul>
                </div>
                <div className="col-md-2 text-start">
                    <h5>{t("fooder.Business")}</h5>
                    <ul>
                        <li><a href="https://evulus.com/about">{t("fooder.About")}</a></li>
                        <li><a href="https://academy.evulus.com">{t("fooder.Academy")}</a></li>
                    </ul>
                    <a href="https://evulus.com/evulus-token" class="btn-evulus-small">Evulus Token</a>
                </div>

                <div className="col-md-2 text-start">
                    <h5>       {t("fooder.Support")}</h5>
                    <ul>
                        <li><a href="https://t.me/joinchat/gf7v1HXj6UplOGIx">{t("fooder.Help Center")}</a></li>
                        <li><a href="https://t.me/joinchat/gf7v1HXj6UplOGIx">{t("fooder.Contact us")}</a></li>
                        <li><a href="https://evulus.com/register">{t("fooder.Create Account")}</a></li>
                      
                    </ul>
                </div>

                <div className="col-md-2 text-start">
                    <h5>{t("fooder.News")}</h5>
                    <ul>
                        <li><a href="https://blog.evulus.com/">Evulus Blog</a></li>
                    </ul>
                </div>
            </div>
        </div>

    </footer>
  )
}
