import React from 'react'
import Staking from '../components/home/Staking';
import Requisitos from '../components/home/Requisitos';
import Pasos from '../components/home/Pasos';
import Reglas from '../components/home/Reglas';
import Footer from '../components/home/Footer';
import Contactanos from '../components/home/Contactanos';
import {useTranslation} from "react-i18next"
export default function Home() {
  const[t,i18n] = useTranslation("global");
  React.useEffect(()=> {
    document.title = t("header.metatitle")
    document.querySelector('meta[name="description"]').setAttribute("content", 'hola');

  })
  return (
    <main>
      <Staking/>
      <Requisitos/>
      <Pasos/>
      <Reglas/>
      <Contactanos/>
      <Footer/>
    </main>
  )
}
