import React from 'react'
import NumberFormat from 'react-number-format';
import {useTranslation} from "react-i18next"
export default function PanelBalance({mybalance,rewards,stake}) {
  const[t,i18n] = useTranslation("global");
  return (
    <div className="panel__valance d-flex">
        <div className="valance-item">
            <div className="valance-item__title">Staked</div>
            <div className="valance-item__total"><NumberFormat value={stake} displayType={'text'} thousandSeparator={true}/></div>
            <span> EVU</span>
        </div>
        <div className="valance-item">
            <div className="valance-item__title">{t("Staked.Profit")}</div>
            <div className="valance-item__total"><NumberFormat value={rewards} displayType={'text'} thousandSeparator={true}/></div>
            <span> EVU</span>
        </div>
        <div className="valance-item">
            <div className="valance-item__title">Balance</div>
            <div className="valance-item__total"><NumberFormat value={mybalance} displayType={'text'} thousandSeparator={true}/></div>
            <span> EVU</span>
        </div>
    </div>

  )
}
